<template>
  <vx-card class="edit-sale-invoice mx-auto main-box top-zero-radius">

    <vs-row class="general-fields">

      <vs-col class="sm:pr-2 md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.invoices.labels.status')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               :is-empty="true"
                               v-model="invoice.status"/>
      </vs-col>

      <vs-col class="sm:pr-2 md:w-1/4 sm:w-1/2">
        <custom-number-input :label="$t('sales.invoices.labels.id')"
                             :placeholder="$t('sales.invoices.placeholder.id')"
                             :is-empty="true"
                             :styles="{width: '100%'}"
                             :disabled="true"
                             v-model="invoice.id"/>
      </vs-col>

      <vs-col class="sm:pr-2 md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.invoices.labels.actionType')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               :is-empty="true"
                               v-model="invoice.actionType"/>
      </vs-col>

      <vs-col class="md:w-1/4 sm:w-1/2">
        <custom-validate-input :label="$t('sales.invoices.labels.date')"
                               :styles="{width: '100%'}"
                               :disabled="true"
                               :is-empty="true"
                               v-model="invoice.date"/>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col class="">
        <div class="owner-info user">
          <span class="title">{{ $t('sales.invoices.labels.userInfo') }}</span>

          <div @click="imageUserPreviewStatus = true"
               class="user-image">
            <lazy-image class="profile-avatar cursor-pointer"
                        :src="invoice.user.avatar"
                        :alt="invoice.user.name"/>
          </div>

          <image-preview v-if="imageUserPreviewStatus"
                         :image="{src: invoice.user.avatar, alt: invoice.user.name}"
                         @closed="imageUserPreviewStatus = false"/>

          <div class="user-info">
            <template v-for="(userInfo, key) in getAsiderUserProfileInfo">
              <div class="user-info-item" :key="key">
                <span class="pre-content">{{ userInfo.label }}</span>
                <span class="content">{{ userInfo.content }}</span>
              </div>
            </template>
          </div>
        </div>
      </vs-col>

      <vs-col class="">
        <div class="coach-info">
          <span class="title">{{ $t('sales.invoices.labels.coachInfo') }}</span>

          <div @click="imageCoachPreviewStatus = true"
               class="user-image">
            <lazy-image class="profile-avatar cursor-pointer"
                        :src="invoice.coach.avatar"
                        :alt="invoice.coach.name"/>
          </div>

          <image-preview v-if="imageCoachPreviewStatus"
                         :image="{src: invoice.coach.avatar, alt: invoice.coach.name}"
                         @closed="imageCoachPreviewStatus = false"/>

          <div class="user-info">
            <template v-for="(userInfo, key) in getAsiderCoachProfileInfo">
              <div class="user-info-item" :key="key">
                <span class="pre-content">{{ userInfo.label }}</span>
                <span class="content">{{ userInfo.content }}</span>
              </div>
            </template>

            <div class="edit-btn" @click="selectCoachPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </div>
        </div>
      </vs-col>
      <vs-col class="">
        <div class="owner-info reserve">
          <span class="title">{{ $t('sales.invoices.labels.reserveInfo') }}</span>

          <div @click="imageReservePreviewStatus = true"
               class="user-image">
            <lazy-image class="profile-avatar cursor-pointer"
                        :src="invoice.reserveInfo.avatar"
                        :alt="invoice.reserveInfo.courtName"/>
          </div>

          <image-preview v-if="imageReservePreviewStatus"
                         :image="{src: invoice.reserveInfo.avatar, alt: invoice.reserveInfo.courtName}"
                         @closed="imageReservePreviewStatus = false"/>

          <div class="user-info">
            <template v-for="(userInfo, key) in getAsiderReserveProfileInfo">
              <div class="user-info-item" :key="key">
                <span class="pre-content">{{ userInfo.label }}</span>
                <span class="content">{{ userInfo.content }}</span>
              </div>
            </template>

            <div class="edit-btn" @click="selectCourtPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>


    <div class="products-fields">
      <vs-col v-if="invoice.user.id > 0" class="sale-addon-list-table">
        <span v-if="hasSaleAddon" class="title">{{ $t('sales.invoices.labels.addonInfo') }}</span>
        <select-sale-addons :user-id="invoice.user.id" :real-time="true"
                            :court-price="parseInt(invoice.reserveInfo.price)" :selected-sale-addons="invoice.lines"
                            @hasSaleAddon="hasSaleAddon = true"
                            @inserted="handleNewSaleAddonInserted($event)"/>


        <div v-if="invoice.reserveInfo.price > 0" class="addon-footer">
          <custom-price-input class="pr-2 md:w-1/3 total-price"
                              :label="$t('sales.invoices.labels.totalPrice')"
                              input-style="line-height: 30px;"
                              :disabled="true"
                              :value="invoice.totalPrice"/>
          <custom-price-input class="pr-2 md:w-1/3 discount"
                              :label="$t('sales.invoices.labels.totalDiscount')"
                              input-style="line-height: 30px;"
                              v-model="invoice.discount"/>

          <custom-price-input class="md:w-1/3"
                              :label="$t('sales.invoices.labels.finalPrice')"
                              input-style="line-height: 30px;"
                              :disabled="true"
                              :value="invoice.finalPrice"/>
        </div>
      </vs-col>
    </div>

    <!-- coaches List prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="selectCoachPromptStatus"
      @close="selectCoachPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('selectUsers')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('coaches.select.title')}}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="selectCoachPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <select-active-coaches :multi-select="false" :selected-times="[invoice.reserveInfo]" @selected="handleSuggestCoachSelected($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- edit court prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="selectCourtPromptStatus"
      @close="selectCourtPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="">-->
            <!--              <custom-icon icon="CHECK" color="success"/>-->
            <!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.labels.selectTime') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="selectCourtPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <select-time-sale-invoice :in-modal="true" :multi-select="false" @selected="handleTimeSelected($event)"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <!-- insert new sale addon prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="selectSaleAddonPromptStatus"
      @close="selectSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertInvoiceSaleAddonsBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.addons.list.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="selectSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <select-sale-addons :user-id="invoice.user.id" @inserted="handleNewSaleAddonInserted($event)"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <!-- receive from customer -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="receiveFromCustomerPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <!--          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                      <div>
                        <custom-icon icon="CHECK" color="success"/>
                      </div>
                    </vs-col>-->

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.receive.insert.dynamicTitle', {name: invoice.user.name.value}) }}
          </vs-col>

          <vs-spacer/>

          <!--          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                      <router-link :to="{name: 'saleInvoice', params: {id: this.$route.params.id}}">
                        <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                      </router-link>
                    </vs-col>-->
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <insert-fast-receive :user="invoice.user" :price="userDebt"
                             :subject="invoice.id.value ? $t('sales.invoices.receive.dynamicTitle', {id: invoice.id.value}) : $t('sales.invoices.receive.title')"/>

        <div class="action-buttons">
          <button class="payment" @click="handleClick('fastReceiveBTN')">{{ $t('sales.invoices.receive.labels.payment')
            }}
          </button>

          <button @click="receiveFromCustomerPromptStatus = false" class="cancel">{{
            $t('sales.invoices.receive.labels.cancel') }}
          </button>
        </div>
      </div>
    </vs-prompt>

    <!-- select cancel type -->
    <vs-prompt
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="showCancelTypePromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <!--          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                      <div>
                        <custom-icon icon="CHECK" color="success"/>
                      </div>
                    </vs-col>-->

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.invoice.cancel.title') }}
          </vs-col>

          <vs-spacer/>

          <!--          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                      <router-link :to="{name: 'saleInvoice', params: {id: this.$route.params.id}}">
                        <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                      </router-link>
                    </vs-col>-->
        </vs-row>
      </div>

      <div class="prompt-content p-3">
        <div class="action-buttons">
          <button class="payment" @click="invoice.cancelType = 1, $refs.returnSaleInvoiceConfirmation.showDialog()">
            {{ $t('sales.invoices.invoice.cancel.byCustomer') }}
          </button>

          <button @click="invoice.cancelType = 2, $refs.returnSaleInvoiceConfirmation.showDialog()" class="cancel">
            {{ $t('sales.invoices.invoice.cancel.bySystem') }}
          </button>
        </div>
      </div>
    </vs-prompt>

    <vs-button id="returnSaleInvoiceBTN"
               class="w-full mt-3"
               color="danger"
               v-show="false"
               @click="$refs.returnSaleInvoiceConfirmation.showDialog()">
      {{ $t('sales.invoices.invoice.labels.cancelReserve') }}
    </vs-button>

    <custom-dialog ref="returnSaleInvoiceConfirmation"
                   :title="$t('sales.invoices.invoice.confirmations.cancelReserve.title')"
                   :body="$t('sales.invoices.invoice.confirmations.cancelReserve.body')"
                   @accept="handleReturnSaleInvoice"/>


    <vs-button id="returnRowSaleInvoiceBTN"
               class="w-full mt-3"
               color="danger"
               v-show="false"
               v-if="checkUserPermissions('invoice.cancel_addon')"
               @click="!selectDeletedRow.court ? $refs.returnRowSaleInvoiceConfirmation.showDialog() : handleCancelSaleAddon()">
      {{ $t('sales.invoices.invoice.labels.cancelAddon') }}
    </vs-button>

    <custom-dialog ref="returnRowSaleInvoiceConfirmation"
                   :title="$t('sales.invoices.invoice.confirmations.cancelAddon.title')"
                   :body="$t('sales.invoices.invoice.confirmations.cancelAddon.body')"
                   @accept="handleCancelSaleAddon"/>


    <vs-button v-if="this.checkUserPermissions('invoice.delete')"
               id="deleteSaleInvoiceBTN"
               class="useral-action-button"
               @click="$refs.deleteSaleInvoiceConfirmation.showDialog()"/>

    <custom-dialog ref="deleteSaleInvoiceConfirmation"
                   :title="$t('sales.invoices.invoice.confirmations.delete.title')"
                   :body="$t('sales.invoices.invoice.confirmations.delete.body')"
                   @accept="deleteInvoice"/>
    <!-- /receive from customer -->

    <button id="insertSaleAddon" v-show="false" @click="selectSaleAddonPromptStatus = true"/>
    <button id="editInvoiceBTN" v-show="false" @click="sendData"></button>
  </vx-card>
</template>

<script>
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
  import CustomNumberInput from '@/components/customInput/customNumberInput'
  import TimeLine from '@/components/timeline/TimeLine'
  import {
    getSaleInvoice,
    cancelSaleInvoice,
    cancelSaleAddon,
    deleteSaleInvoice, insertSaleInvoiceAddon, editSaleInvoice
  } from '../../../../../http/requests/booking/sales'
  import InsertFastReceive from '../../../treasury/receive/insert/insertFastReceive'
  import axios from 'axios'
  import CustomDialog from "../../../../../components/customDialog/customDialog";
  import {
    addComma,
    checkUserPermissions,
    getAvatarUrl, getStaticFileUrl,
    hideLoading,
    showLoading
  } from "../../../../../assets/js/functions";
  import LazyImage from "../../../../../components/lazyImage/lazyImage";
  import ImagePreview from "../../../../../components/imagePreview/imagePreview";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import SelectSaleAddons from "./selectSaleAddons";
  import InsertUser from "../../../customers/insert/insertUser";
  import SelectUsers from "../../../customers/select/selectUsers";
  import SelectTimeSaleInvoice from "./selectTimeSaleInvoice";
  import CustomPriceInput from "../../../../../components/customInput/customPriceInput";
  import SelectActiveCoaches from "../../../coaches/select/selectActiveCoaches";

  export default {
    name: 'editSaleInvoice',
    metaInfo() {
      return {
        title: this.invoice.type && this.invoice.id.value ? this.$t('sales.invoices.invoice.dynamicTitleWithId', {
          type: this.invoice.type,
          id: this.invoice.id.value
        }) : this.$t('sales.invoices.edit.title')
      }
    },
    components: {
      SelectActiveCoaches,
      CustomPriceInput,
      SelectTimeSaleInvoice,
      SelectUsers,
      InsertUser,
      SelectSaleAddons,
      CustomIcon,
      ImagePreview,
      LazyImage,
      CustomDialog,
      InsertFastReceive,
      TimeLine,
      CustomNumberInput,
      SuggestBox,
      CustomValidateInput
    },
    data() {
      return {
        imageUserPreviewStatus: false,
        imageCoachPreviewStatus: false,
        imageReservePreviewStatus: false,
        receiveFromCustomerPromptStatus: false,
        showCancelTypePromptStatus: false,
        selectSaleAddonPromptStatus: false,
        selectCourtPromptStatus: false,
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        selectCoachPromptStatus: false,
        userDebt: 0,
        hasSaleAddon: false,
        selectedSaleAddon: [],
        selectDeletedRow: {},
        invoice: {
          actionType: {
            value: '',
            isValid: true
          },
          cancelType: 1,
          id: {
            value: '',
            isValid: true
          },
          status: {
            value: '',
            isValid: true
          },
          passed: true,
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            },
            avatar: '',
            gender: '',
            phoneNumber: ''
          },
          coach: {
            id: null,
            name: {
              value: '',
              isValid: true
            },
            avatar: '',
            gender: '',
            phoneNumber: '',
            price: 0
          },
          reserveInfo: {
            avatar: '',
            courtName: '',
            time: '',
            date: '',
            price: ''
          },
          address: {
            value: '',
            isValid: true
          },
          date: {
            value: '',
            isValid: true
          },
          type: '',
          discount: {
            value: '0',
            isValid: true
          },
          totalPrice: {
            value: '0',
            isValid: true
          },
          finalPrice: {
            value: '0',
            isValid: true
          },
          lines: [],
          selectedSaleAddon: []
        },
        invoiceProcesses: [
          {
            label: this.$t('sales.invoices.statusTypes.active'),
            value: 1
          },
          {
            label: this.$t('sales.invoices.statusTypes.canceled'),
            value: 2
          },
          {
            label: this.$t('sales.invoices.statusTypes.activePassed'),
            value: 3
          },
          {
            label: this.$t('sales.invoices.statusTypes.canceledBySystem'),
            value: 4
          },
        ],
        invoiceType: [
          {
            label: this.$t('sales.invoices.actionTypes.present'),
            value: 1
          },
          {
            label: this.$t('sales.invoices.actionTypes.tel'),
            value: 2
          },
          {
            label: this.$t('sales.invoices.actionTypes.internet'),
            value: 3
          }
        ],
        actions: [
          {
            toolbar: [
              {
                id: 'editInvoiceBTN',
                icon: 'SAVE',
                iconPack: 'useral',
                color: 'success',
                permission: 'invoice.update'
              }
            ],
            leftToolbar: [
              {
                id: 'deleteSaleInvoiceBTN',
                icon: 'TRASH',
                iconPack: 'useral',
                color: 'danger',
                permission: 'invoice.delete'
              },
              {
                id: 'routeBackBTN',
                route: {name: 'saleInvoice'},
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    computed: {
      getAsiderUserProfileInfo() {
        const info = [
          {
            label: this.$t('sales.invoices.labels.fullName'),
            content: this.invoice.user.name.value
          },
          {
            label: this.$t('sales.invoices.labels.phoneNumber'),
            content: this.invoice.user.phoneNumber
          },
          {
            label: this.$t('sales.invoices.labels.gender'),
            content: this.invoice.user.gender === 1 ? this.$t('genderTypes.man') : this.invoice.user.gender === 2 ? this.$t('genderTypes.woman') : ''
          },
        ]
        return info
      },
      getAsiderCoachProfileInfo() {
        const info = [
          {
            label: this.$t('sales.invoices.labels.fullName'),
            content: this.invoice.coach.name.value
          },
          {
            label: this.$t('sales.invoices.labels.phoneNumber'),
            content: this.invoice.coach.phoneNumber
          },
          {
            label: `${this.$t('sales.invoices.labels.coachPrice')}: `,
            content: `${addComma(this.invoice.coach.price)} ${this.$locale.currency()}`
          }
        ]
        return info
      },
      getAsiderReserveProfileInfo() {
        const info = [
          {
            label: this.$t('sales.invoices.labels.courtName'),
            content: this.invoice.reserveInfo.courtName
          },
          {
            label: this.$t('sales.invoices.labels.reserveTime'),
            content: `${this.invoice.reserveInfo.time} - ${this.invoice.reserveInfo.date}`
          },
          {
            label: this.$t('sales.invoices.labels.price'),
            content: `${addComma(this.invoice.reserveInfo.price)} ${this.$locale.currency()}`
          }
        ]
        return info
      },
      computeTotalPrice() {
        let price = this.invoice.reserveInfo.price
        price += parseInt(this.invoice.coach.price)
        if (this.invoice.lines.length > 0) {
          this.invoice.lines.forEach((item) => {
            price += parseInt(item.finalPrice.value.toString().split(',').join(''))
          })
        }
        return price.toString()
      }
    },
    created() {
      if (this.$route.name === 'saleInvoiceTrash') {
        this.actions[0].leftToolbar.splice(1, 1)
        this.actions[0].leftToolbar[1].id = {name: 'saleInvoicesTrash'}
      }

      this.$nextTick(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', ' md-max-w-half mx-auto')
      })

      this.getSaleInvoice()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getSaleInvoice() {
        getSaleInvoice({id: this.$route.params.id}).then(response => {
          const invoice = response.data.data

          let price = invoice.court_price

          const addons = []
          if (invoice.addons.length > 0) {
            invoice.addons.forEach(addon => {
              addons.push({
                id: addon.id,
                rowNumber: addons.length + 1,
                name: addon.name,
                maxSelect: addon.max_select,
                required: addon.required,
                quantity: addon.quantity,
                unitPrice: {value: addon.price, type: 'price'},
                finalPrice: {value: addon.price * addon.quantity, type: 'price'}
              })
              price += addon.price * addon.quantity
            })
          }
          price += invoice.coach ? (invoice.coach.price - invoice.coach.discount) : 0
          this.invoice.totalPrice = {
            value: invoice.total_price + invoice.discount,
            isValid: true
          }
          this.invoice.lines = addons

          this.invoice.id = {value: invoice.invoice_number || invoice.id, isValid: true}
          this.invoice.date = {value: invoice.invoice_date, isValid: true}
          this.invoice.user = {
            id: invoice.user.id,
            name: {
              value: `${invoice.user.name} ${invoice.user.family}`,
              isValid: true
            },
            avatar: invoice.user.avatar ? getAvatarUrl(invoice.user.avatar) : invoice.user.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
            gender: invoice.user.gender,
            phoneNumber: invoice.user.phone_number
          }
          this.invoice.coach = {
            id: invoice.coach ? invoice.coach.id : null,
            name: {
              value: invoice.coach ? `${invoice.coach.name} ${invoice.coach.family}` : '',
              isValid: true
            },
            avatar: invoice.coach && invoice.coach.avatar ? getAvatarUrl(invoice.coach.avatar) : require('@/assets/images/man-avatar.png'),
            phoneNumber: invoice.coach ? invoice.coach.phone_number : '',
            price: invoice.coach ? invoice.coach.price : 0
          }
          this.invoice.reserveInfo = {
            avatar: invoice.reserved_time.court.avatar ? getAvatarUrl(invoice.reserved_time.court.avatar) : require('@/assets/images/no-image.png'),
            courtName: invoice.reserved_time.court.name || '',
            courtId: invoice.reserved_time.court.id,
            time: `${invoice.reserved_time.time.start_time.substr(0, 5)} تا ${invoice.reserved_time.time.end_time.substr(0, 5)}`,
            timeId: invoice.reserved_time.time.id,
            termId: invoice.reserved_time.term.id,
            date: invoice.reserved_time.date.split(' ')[0],
            price: invoice.court_price
          }
          this.invoice.discount.value = invoice.discount.toString()
          let status = ''
          if (invoice.status === 1) {
            if (invoice.passed) {
              status = this.invoiceProcesses[2]
            } else {
              status = this.invoiceProcesses[0]
            }
          } else if (invoice.status === 2) {
            status = invoice.cancel_type === 1 ? this.invoiceProcesses[2] : this.invoiceProcesses[3]
          }
          this.invoice.status = {id: invoice.status, value: status.label, isValid: true}
          this.invoice.actionType = {
            id: invoice.action_type,
            value: this.getInvoiceActionType(invoice.action_type),
            isValid: true
          }

          if (invoice.address) {
            this.invoice.address = {
              value: `${invoice.address.province}, ${invoice.address.city}, ${invoice.address.address}`,
              isValid: true
            }
          }

          switch (invoice.action_type) {
            case 1:
              this.invoice.type = this.$t('sales.invoices.actionTypes.shortPresent')
              break

            case 2:
              this.invoice.type = this.$t('sales.invoices.actionTypes.shortTel')
              break

            case 3:
              this.invoice.type = this.$t('sales.invoices.actionTypes.shortInternet')
              break
          }

          const actions = JSON.parse(JSON.stringify(this.actions[0]))
          const insertSaleAddonIndex = actions.toolbar.map(e => e.id).indexOf('insertSaleAddon')
          if (invoice.status === 2 && insertSaleAddonIndex > -1) {
            actions.toolbar.splice(insertSaleAddonIndex, 1)
          }

          setTimeout(() => {
            this.$store.dispatch('updateNavbarActions', actions)
          }, 50)

          if (!this._isBeingDestroyed) {
            this.$store.dispatch('setPageTitle', this.$t('sales.invoices.edit.dynamicTitle', {type: this.invoice.type}))
          }
        })
      },
      getInvoiceStatus(id) {
        return this.invoiceProcesses[id - 1].label
      },
      getInvoiceActionType(id) {
        return this.invoiceType[id - 1].label
      },
      handleSuggestUserSelected(list) {
        let user = {}
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            user = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2
            }
          }
        })
        this.invoice.user = user
        this.suggestUserPromptStatus = false
      },
      handleNewUserInserted(user) {
        this.newInvoice.user.id = user.id
        this.newInvoice.user.name.value = `${user.name} ${user.family}`
        this.newSuggestUserPromptStatus = false
      },
      handleTimeSelected(list) {
        const reserve = Object.values(list)[0]
        this.invoice.reserveInfo = {
          avatar: require('@/assets/images/no-image.png'),
          courtName: reserve.court.court_name || '',
          courtId: reserve.court.id,
          time: `${reserve.time.start_time.substr(0, 5)} ${this.$t('sales.invoices.labels.until')} ${reserve.time.end_time.substr(0, 5)}`,
          timeId: reserve.time.id,
          termId: reserve.termId,
          date: reserve.date.split(' ')[0],
          price: reserve.price
        }


        let price = parseInt(reserve.price)
        this.invoice.lines.forEach(item => {
          price += parseInt(item.finalPrice.value.toString().split(',').join(''))
        })
        this.invoice.totalPrice = {
          value: price.toString(),
          isValid: true
        }

        this.selectCourtPromptStatus = false
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      addComma(val) {
        let sign = ''

        if (val < 0) sign = '-'

        val = val.toString()

        if (val[0] === '0') val = val.substr(1, val.length - 1)

        return val.toString().replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
      },
      handleReturnSaleInvoice() {
        const payload = {
          cancel_type: this.invoice.cancelType
        }
        cancelSaleInvoice(this.$route.params.id, payload).then(response => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.invoices.notifications.cancel.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.showCancelTypePromptStatus = false
          this.$router.replace({name: 'saleInvoices'})
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              switch (error.response.status) {
                case 409:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.timesCanceled'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.cancel.error'),
                    time: 2400,
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather'
                  })
                  break
              }
            }
          })
      },
      handleSuggestCoachSelected(list) {
        let coach = {}
        if (list.length > 0) {
          this.invoice.totalPrice.value = parseInt(this.invoice.totalPrice.value) - parseInt(this.invoice.coach.price)
          this.invoice.finalPrice.value = parseInt(this.invoice.finalPrice.value) - parseInt(this.invoice.coach.price)
        }
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            coach = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2,
              phoneNumber: data.phoneNumber,
              price: data.price.value
            }


            this.invoice.totalPrice.value = parseInt(this.invoice.totalPrice.value) + parseInt(data.price.value)
            this.invoice.finalPrice.value = parseInt(this.invoice.finalPrice.value) + parseInt(data.price.value)
          }
        })
        this.invoice.coach = coach
        this.selectCoachPromptStatus = false
      },
      handleCancelSaleAddon() {
        const row = this.selectDeletedRow
        if (!row.court) {
          if (!row.required) {
            const payload = {
              cancel_type: 1
            }
            cancelSaleAddon(this.$route.params.id, row.id, payload).then(() => {
              this.$vs.notify({
                title: this.$t('alert.message.title'),
                text: this.$t('sales.invoices.notifications.cancelSaleAddon.success'),
                color: 'success',
                icon: 'icon-check',
                iconPack: 'feather',
                time: 2400
              })
              this.getSaleInvoice()
            })
              .catch(error => {
                if (axios.isCancel(error)) {
                  this.$vs.notify({
                    title: this.$t('alert.duplicateRequest.title'),
                    text: this.$t('alert.duplicateRequest.message'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400,
                    color: 'warning'
                  })
                } else {
                  switch (error.response.status) {
                    case 409:
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: this.$t('sales.invoices.notifications.addonCanceled'),
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      break
                    default:
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: this.$t('sales.invoices.notifications.cancelSaleAddon.error'),
                        time: 2400,
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather'
                      })
                      break
                  }

                }
              })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('sales.invoices.notifications.cantDeleteAddon'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.invoices.notifications.cantDeleteCourt'),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }

      },

      handleNewSaleAddonInserted(list) {
        if (list && list.length > 0) {
          this.invoice.lines = []
          let price = parseInt(this.invoice.reserveInfo.price)
          list.forEach(item => {
            this.invoice.lines.push(item)
            price += parseInt(item.finalPrice.value.toString().split(',').join(''))
          })
          this.invoice.totalPrice = {
            value: price.toString(),
            isValid: true
          }
        }
      },
      handleOpenSelectSaleAddonPrompt(index) {
        this.selectSaleAddonPromptStatus = true
        this.selectedIndex = index
      },
      sendData() {
        let errorNum = 0
        const addons = []
        if (this.invoice.lines.length > 0) {
          this.invoice.lines.forEach((product) => {
            addons.push({
              sale_addon_id: product.id,
              quantity: product.quantity
            })
          })
        }

        if (errorNum === 0) {
          const data = {
            status: this.invoice.status.id,
            action_type: this.invoice.actionType.id,
            invoice_number: parseInt(this.invoice.id.value) ? parseInt(this.invoice.id.value) : null,
            time_id: this.invoice.reserveInfo.timeId,
            court_id: this.invoice.reserveInfo.courtId,
            term_id: this.invoice.reserveInfo.termId,
            coach_id: this.invoice.coach.id,
            // gender: this.invoice.user.gender,
            date: this.invoice.reserveInfo.date,
            unit_price: this.invoice.reserveInfo.price,
            discount: parseInt(this.invoice.discount.value),
            quantity: 1,
            addons: addons,
            // user_id: this.invoice.user.id
          }

          editSaleInvoice(this.$route.params.id, data).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('sales.invoices.notifications.edit.success'),
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400
            })
            this.$router.replace({name: 'saleInvoices'})
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'user_id': '',
                'date': this.$t('sales.invoices.notifications.parseError.date'),
              }
              switch (error.response.status) {
                case 409:
                  // if (error.response.data && error.response.data.data && error.response.data.data.hasOwnProperty('user_debt')) {
                  const newInvoice = error.response.data

                  if (newInvoice.error_type === 'balance_error') {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.lowMoneyForAddon'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })

                    this.userDebt = Math.abs(newInvoice.money_deficit)
                    // this.invoice.id.value = newInvoice.id
                    this.receiveFromCustomerPromptStatus = true
                  } else if (newInvoice.error_type === 'coach_error') {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.coachCantSelectEdit'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 5000
                    })

                  } else {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: this.$t('sales.invoices.notifications.timesReserved'),
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                  }
                  break

                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('sales.invoices.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      },
      deleteInvoice() {
        deleteSaleInvoice(this.$route.params.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.invoices.notifications.delete.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.$router.replace({name: 'saleInvoices'})
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 409:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('sales.invoices.notifications.cantDelete'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
              default:
                this.$vs.notify({
                  title: this.$t('alert.message.title'),
                  text: this.$t('sales.invoices.notifications.delete.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      },
    },
    watch: {
      '$store.state.helper.documentsChanged': {
        handler(val) {
          if (val) {
            this.receiveFromCustomerPromptStatus = false
            this.sendData()
          }
        },
        deep: true
      },
      'invoice.discount.value': {
        handler(val) {
          this.invoice.finalPrice.value = (parseInt(this.invoice.totalPrice.value) - parseInt(val)).toString()
        }
      },
      'invoice.totalPrice.value': {
        handler(val) {
          this.invoice.finalPrice.value = (parseInt(val) - parseInt(this.invoice.discount.value)).toString()
        }
      }
    },
  }
</script>

<style lang="scss">
  @import "../../../../../assets/scss/vuexy/variables";

  .edit-sale-invoice {
    width: 50%;
    min-width: 700px;

    @media screen and (max-width: 800px) {
      min-width: unset;
      width: 100%;
    }

    .vx-card__body {
      padding: 0 !important;
      display: flex;
      flex-direction: column;

      .general-fields, .products-fields, .owner-info, .coach-info {
        padding: .5rem;
      }

      .process-timeline {
        background: #cecece22;
      }

      .general-fields {
        background: #cecece22;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0;

        .custom-validate-input label {
          //background-color: #ffffff;
        }

        .custom-validate-input label span.is-focus,
        .custom-number-input label span.is-focus {
          //backdrop-filter: sepia(1);
          //background: linear-gradient(180deg, #f8f8f8, #ffffff);
        }
      }

      .products-fields {
        flex-grow: 1;
        height: calc(100% - 336px);

        .sale-addon-list-table {
          margin-top: 8px;
          min-height: 177px;
          position: relative;
          height: calc(100% - 86px);

          .title {
            font-size: 14px;
            font-weight: bold;
            position: absolute;
            top: -12px;
            left: 10px;
            padding: 0 5px;
            background-color: #ffffff;
          }

          .insert-invoice-select-sale-addon {
            .sale-addon-items {
              padding: 0.5rem;
              padding-top: 20px;
              border: 1px solid #cdcdcd;
              border-radius: 0.5rem;
              flex-grow: 1;
            }
          }

          .addon-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #cdcdcd;
            border-radius: 0.5rem;
            height: 70px;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding: 0.5rem;

            .discount {
              label {
                input,
                span:not(.input-label) {
                  color: $danger;
                }
              }
            }

            .total-price {
              label {
                input,
                span:not(.input-label) {
                  color: $success;
                }
              }
            }
          }

          .draggable-dynamic-table {
            .new-suggest-modal-button {
              display: none;
            }

            .suggest-modal-button {
              right: 7px !important;
            }
          }
        }
      }

      .owner-info, .coach-info {
        position: relative;
        margin: 15px 0.5rem 0;
        height: calc(100% - 15px);
        border: 1px solid #cdcdcd;
        border-radius: 0.5rem;
        display: flex;

        .title {
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          top: -12px;
          left: 10px;
          padding: 0 5px;
          background-color: #ffffff;
        }

        .user-image {
          max-width: 50px;
          max-height: 50px;
          border-radius: 0.5rem;
          overflow: hidden;
          display: flex;
          align-items: center;

          .profile-avatar {
            width: 100%;
            object-fit: cover;
          }
        }

        .user-info {
          padding: 0 0.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .user-info-item {
            line-height: 32px;

            .pre-content {
              font-size: 12px;
              color: #888888;
            }

            .content {
              font-weight: bold;
            }
          }

          .edit-btn:hover {
            cursor: pointer;
          }
        }

      }
    }

    .select-user-invoice {
      position: relative;

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 42px;
        top: 20px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 12px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      .suggest-box {

        ul {
          margin-top: -10px;
          border-radius: .5rem;
          padding: 0 10px;
          line-height: 35px;
          background: white;
          box-shadow: 0 5px 10px -8px;
          border: 1px solid #cecece;
          /*right: .5rem;
          left: .5rem;*/

          &::-webkit-scrollbar {
            display: none;
          }

          li {
            cursor: pointer;
            border-top: 1px solid #cecece;

            &:first-child {
              border-top: none !important;
            }
          }
        }
      }
    }

    table {
      border: 1px solid #1b1b1c;
      border-radius: 5px;

      tr {

        div.select-suggest-list {
          right: 0 !important;
          left: 0 !important;
        }
      }
    }
  }

  .action-buttons {
    display: flex;

    button {
      flex: 1;
      line-height: 40px;
      border-radius: .5rem;
      border: none;
      color: #ffffff;
      transition: all .3s ease;
      cursor: pointer;

      &.payment {
        margin-right: 10px;
        background: #33b000cf;

        &:hover {
          background: #33b000;
        }
      }

      &.cancel {
        background: #b00000af;

        &:hover {
          background: #b00000;
        }
      }
    }
  }
</style>
